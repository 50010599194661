import logo from './logo.svg';
import './App.css';
import {ReactComponent as ReactLogo} from './logo.svg';
function App() {
  return (
    <div className="Sentigel">
      <body className ="Sentigel-body">
        <h1 hidden={true}>SentiGel</h1>
        <div className='Sentigel-logo'>
        <ReactLogo width={"100%"} height={"100%"}  alt="Sentigel Logo"/>
       </div>
         <p className="Sentigel-contact">Contact: <a href="mailto:info@SentiGel.com">info@SentiGel.com</a></p>
      </body>
    </div>
  );
}

export default App;
